@import 'app/style/material';
@import 'app/style/mixins';
@import 'app/style/form';
@import 'app/style/sticky-header';
@import 'app/style/sidenav';
@import 'app/style/utility/utility';
@import 'app/style/utility/variables';
@import 'app/style/button';

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  user-select: none;
}

[hidden] {
  display: none !important;
}

/* Scrollbar */

/* Width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cacaca;
  border-radius: 10px;
}

body {
  margin: 0;
}

.main-container {
  input:focus,
  input:active,
  input:hover,
  select:focus,
  select:active,
  select:hover,
  textarea:focus,
  textarea:active,
  textarea:hover {
    border: none;
  }
  .eis-btn-primary:hover,
  .eis-btn-primary:focus,
  .eis-btn-primary:active {
    background-color: unset;
    outline: unset;
    border: unset;
    color: unset;
  }
}

.cdk-overlay-backdrop {
  z-index: 0 !important;
}

.mat-button,
.mat-icon-button {
  &.cdk-focused,
  &.cdk-program-focused {
    background-color: transparent;
    .mat-button-focus-overlay {
      display: none;
    }
  }
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

global-footer {
  display: block;
  padding: 25px;
}

global-nav {
  position: fixed;
  width: 100%;
  z-index: 999;
}

lib-ngx-deeplinker {
  margin: 0 !important;
  display: block;
  text-align: right;

  button {
    background-color: #fff;
    color: $primary-color;
    border: 1px solid $primary-color;
  }

  ::ng-deep button {
    height: 26px !important;
    padding: 4px 20px !important;
  }
}

.display-container {
  position: relative;
}

.a {
  fill: none !important;
}

button:disabled {
  cursor: not-allowed !important;
  pointer-events: auto;
  background-color: #d8dadc !important;
  color: #999 !important;
  opacity: 1;

  &:hover {
    opacity: 1;
    border: 1px solid #999;
  }
}

.action-btn:disabled {
  cursor: not-allowed !important;
  pointer-events: auto;
  background-color: transparent !important;
  .mat-icon {
    color: #999 !important;
    opacity: 1;
  }
}

button.mat-focus-indicator.mat-icon-button:hover,
button.mat-focus-indicator.mat-icon-button:focus {
  border: none !important;
}

.x-btn-primary:focus {
  background-color: var(--primary-color);
  color: white;
}

.pac-container {
  min-width: 400px !important;
}

.section-header-container {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  margin: 32px 0 0 0;
  border-bottom: 1px #ddd solid;
  padding: 0 0 16px;
  h2 {
    margin-left: 18px;
  }
}

.section-header {
  color: $primary-color;
  font-weight: 400;
}

.expansion-panel {
  border: 1px solid #d4d4d4 !important;
  border-radius: 8px !important;
  box-shadow: none !important;
}

.info-title {
  float: left;
  width: 180px;
  min-width: 180px;
  color: #888888;
  padding-top: 0;
  line-height: 1.5em;
}

.info-value {
  color: #717171;
  line-height: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
}

.back-btn {
  color: $primary-color;
  cursor: pointer;
}

.section-greeting {
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 20px;
  .mat-icon {
    height: 20px;
    width: 20px;
  }
}

.page-container {
  padding: 0 2rem 2rem;
  height: 100% !important;
}

.form-wrapper {
  margin-top: 16px;
}

th,
td {
  padding: 0 2rem;
}

.actions-table {
  thead {
    .mat-header-row {
      :nth-last-child(2) {
        width: 100%;
      }
    }
  }
}
table {
  border-left: 1px solid rgb(0 0 0 / 12%);
  border-right: 1px solid rgb(0 0 0 / 12%);
  width: 100%;
  border-collapse: collapse;
  thead {
    .mat-header-row {
      background-color: #fff;
      border-bottom: 1px solid rgb(0 0 0 / 12%);
      th {
        color: $primary-color;
        font-weight: 600 !important;
        font-size: 14px;
        line-height: 17px;
        white-space: nowrap;
        border-top: 1px solid rgb(0 0 0 / 12%);
        padding: 0 12px;
      }
      .actions-header {
        width: 1%;
      }
      th.mat-header-cell {
        text-align: left;
      }
    }
  }
  tbody {
    tr {
      td {
        display: table-cell;
        text-align: center;
        .active:before {
          content: ' \25CF';
          color: #37a862;
          font-size: 24px;
        }
        .complete:before {
          content: ' \25CF';
          color: #ea4e9d;
          font-size: 24px;
        }
        .cancelled:before {
          content: ' \25CF';
          color: #a2a2a2;
          font-size: 24px;
        }
      }
      .actions-cell {
        flex-direction: row;
        background-color: white;
      }
    }
    th.mat-header-cell:last-of-type,
    td.mat-cell:last-of-type,
    td.mat-footer-cell:last-of-type {
      padding-right: 0px;
    }
  }
}
.table tbody tr:first-child,
.table tr > td {
  cursor: unset;
}
.title-bold {
  font-weight: bold;
}

.static-detail {
  font-weight: normal;
}

.empty-section {
  text-align: center;
  padding-top: 30px;
  h6 {
    color: #333;
    margin: 10px 0;
  }
  mat-icon {
    height: 40px;
    width: 40px;
    margin-bottom: 10px;
  }
  .empty-filler {
    color: #717171;
  }
}

.btn-container {
  display: flex;
}

.table-container-wrapper {
  justify-content: center;
  border: 1px solid #b8b8b8;
  overflow-x: auto;
  margin: 16px 8px;
  width: 100%;
  table {
    width: 100%;
    border: unset;
  }
  table > tbody > tr > td,
  table > tbody > tr > td > a {
    white-space: unset;
  }

  table > tbody > tr > td > span > a,
  table > tbody > tr > td,
  table > tbody > tr > td > a table > tbody > tr > td > span {
    word-break: break-all;
    white-space: normal;
  }
}

.search-container {
  height: 50px;
}

.mat-stroked-button {
  width: 153px;
}

.mat-button-disabled {
  background-color: initial !important;
  border: initial !important;
}

.translated-paragraph {
  white-space: pre-wrap;
}

.mat-icon > svg > path {
  fill: var(--primary-color) !important;
}

.tree-node-margin {
  background-color: var(--primary-color) !important;
}

.tree-node:hover {
  background: rgba(171, 184, 195, 0.5) !important;
}

.ngx-slider-pointer {
  background-color: var(--primary-color) !important;
}
