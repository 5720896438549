/*** Color ***/

$primary-color: var(--primary-color);
$primary-lighter-color: #ddd7ea;
$background-color: #f2f0f9;
$warn-lighter-color: #fd6297;
$alert-success-color: #e0f0e2;
$alert-failure-color: #ffe3ed;
$background-grey: #f2f2f2;
$border-grey: #d9d9d9;
$accent-color: #0072ce;

// $warn-lighter-color: var(--warn-lighter-color);
// $primary-lighter-color: var(--primary-lighter-color);

/*** Text Color ***/

$text-light: #717171;

/***************   */

/*** Sidenav ***/

$sidenav-dock-width: 60px;
$sidenav-width: 400px;

/*** Button ***/

$button-hover: #fcfaff;

/*** Font ***/

$font: var(--font);
